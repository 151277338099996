import Vue from 'vue';

const firebaseConfig = {
  apiKey: 'AIzaSyCgxMc5A7Ic15ckkQCSu3oRcDoJh6rtebA',
  authDomain: 'wing-kei-construct-engine.firebaseapp.com',
  databaseURL: 'https://wing-kei-construct-engine-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'wing-kei-construct-engine',
  storageBucket: 'wing-kei-construct-engine.appspot.com',
  messagingSenderId: '384512855370',
  appId: '1:384512855370:web:9a6435f3498a3bd63585b3',
  measurementId: 'G-8HE40H8H9N',
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return '永基營造工程有限公司'; //公司名稱
      },
      get regFormDomain() {
        return 'https://wingkeiconstructengine.online/';
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor = '#e8f2fa';

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor = '#000';

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export { firebaseConfig, panelColor, sideBarColor };
